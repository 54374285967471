import { Outlet, Link, useSearchParams, useNavigate } from 'react-router-dom';
import './AppLayout.scss';
// import { HistoryButton } from '../../components/buttons/Buttons';
// import { CosmosDBStatus } from '../../types';
import logoUrl from '../../assets/assa-abloy-logo-black.svg';
import { UserMenu } from '../../components/user-menu/UserMenu';
import { ChatMenu } from '../../components/chat-menu';
import { MenuIcon } from '../../components/chat-menu/MenuIcon';
import { useAppContext } from '../../state/useAppProvider';
import { useWindowSize } from '../../hooks/use-window-width';
import { useEffect, useState } from 'react';
import { featureToggles } from '../../state/constants';
import { FeatureToggleList } from '../../types';
import { NavbarProductSelector } from '../../components/chat/product-selector';
import { FilterIcon } from './FilterIcon';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from '../../api/useAuthContext';
import { ChatLoader } from '../../components/chat/chat-loader';
import { useProductHandler } from '../../state/useProductHandler';

const AppLayout = () => {
  const { t } = useTranslation();
  const { currentChat, activeToggles, isChatMenuOpen, dispatch } =
    useAppContext();
  const { userIsNotSetup, userSettings, loadingUser, authLoading } =
    useAuthContext();
  const { selectedProducts } = useProductHandler();
  const { isMobile } = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [productFilterVisible, setProductFilterVisible] = useState(false);
  const productSelected = selectedProducts !== undefined;

  // Redirects to setup-account page if initial user settings have not been setup.
  useEffect(() => {
    if (userIsNotSetup) {
      navigate('/account-setup');
    }
  }, [navigate, userIsNotSetup]);

  // Stores the active feature toggles in the URL, based on list of feature toggles.
  useEffect(() => {
    if (!activeToggles) {
      const activeToggles = featureToggles.reduce((acc, toggle) => {
        const value = searchParams.get(toggle);
        if (value) {
          acc[toggle] = value;
        }
        return acc;
      }, {} as FeatureToggleList);
      dispatch({ type: 'SET_ACTIVE_TOGGLES', payload: activeToggles });
    } else {
      const otherSearchParams = {} as FeatureToggleList;
      for (const [key, value] of searchParams.entries()) {
        if (featureToggles.includes(key)) continue;
        otherSearchParams[key] = value;
      }
      setSearchParams({ ...activeToggles, ...otherSearchParams });
    }
  }, [searchParams, setSearchParams, activeToggles, dispatch]);

  // Defaults to closing the menu when going into mobile view and vice versa.
  useEffect(() => {
    if (isMobile) {
      dispatch({ type: 'SET_CHAT_MENU_OPEN', payload: false });
    } else {
      dispatch({ type: 'SET_CHAT_MENU_OPEN', payload: true });
    }
  }, [dispatch, isMobile]);

  const toggleMenuOpen = () => {
    dispatch({ type: 'SET_CHAT_MENU_OPEN', payload: !isChatMenuOpen });
  };

  useEffect(() => {
    if (currentChat.messages.length > 1 && productSelected) {
      setProductFilterVisible(false);
    }
  }, [currentChat.messages.length, productSelected]);

  if (!userSettings && (loadingUser || authLoading))
    return <ChatLoader message='Loading user data...' />;

  return (
    <div className='layout' data-open={isChatMenuOpen}>
      <button
        data-metrics-id='MenuButton'
        onClick={toggleMenuOpen}
        className='menu-button'
        aria-label={t('chat_menu.menu_button_label')}
      >
        <MenuIcon />
      </button>
      <header className='header' role='banner'>
        <section className='header-container'>
          <div className='title-container'>
            <img src={logoUrl} className='header-icon' aria-hidden='true' />
            <Link to='/' className='header-title-container'>
              <h1 className='header-title'>{t('header.title')}</h1>
            </Link>
          </div>
          <button
            data-metrics-id='FilterMenuButton'
            className='btn-badge filter-button'
            aria-label={t('header.filter_button_label')}
            onClick={() => setProductFilterVisible((prev) => !prev)}
          >
            <FilterIcon />
          </button>
          <UserMenu />
        </section>
        <NavbarProductSelector
          isOpen={productFilterVisible}
          onClose={() => setProductFilterVisible(false)}
          disabled={currentChat.messages.length > 1 && productSelected}
        />
      </header>
      <ChatMenu />
      <Outlet />
    </div>
  );
};

export default AppLayout;
