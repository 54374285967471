export enum Region {
  NA = 'na',
  EU = 'eu',
}

export enum BusinessSegment {
  BSI = 'BSI',
  BSP = 'BSP',
}

export type BusinessSegmentKey = keyof typeof BusinessSegment;

export const languageCodes = [
  'cs-CZ',
  'en-US',
  'es-ES',
  'de-DE',
  'it-IT',
  'nb-NO',
  'nl-NL',
  'fr-FR',
  'pl-PL',
  'pt-PT',
  'sv-SE',
  'fi-FI',
  'da-DK',
] as const;

export type Language = (typeof languageCodes)[number];

export type LanguageList = {
  [code in Language]: string;
};

export const languages: LanguageList = {
  'cs-CZ': 'Čeština',
  'da-DK': 'Dansk',
  'de-DE': 'Deutsch',
  'en-US': 'English',
  'es-ES': 'Español',
  'fr-FR': 'Français',
  'it-IT': 'Italiano',
  'nl-NL': 'Nederlands',
  'nb-NO': 'Norsk',
  'pl-PL': 'Polski',
  'pt-PT': 'Português',
  'fi-FI': 'Suomi',
  'sv-SE': 'Svenska',
};

export const roles = [
  { value: 'technician', display_name: 'Technician' },
  { value: 'technical_lead', display_name: 'Technical Lead' },
  { value: 'first_line_support', display_name: '1st Line of Support' },
  { value: 'team_leader', display_name: 'Team Leader' },
  { value: 'office_management', display_name: 'Office Management/Other' },
] as const;
export type Role = (typeof roles)[number]['value'];

export type UserSettings = {
  user_id: string;
  language: Language;
  region: Region;
  email: string;
  business_segment?: BusinessSegment;
  business_area: string;
  data_filter: BusinessSegment[];
  experience?: string;
  year_of_employment?: string;
  role?: string;
  user_setup_complete?: boolean;
  accepted_ai_use_disclaimer?: boolean;
};

export type Name = {
  fullName: string;
  firstName: string;
  lastName: string;
  initials: string;
};

export type UserData = {
  id: string;
  name: Name;
  userSettings: UserSettings;
};

export type UserSetupData = {
  id: string;
  email: string;
  name: Name;
  business_area: string;
  business_segment?: BusinessSegment;
};

export type IdTokenPayload = {
  aio: string;
  aud: string;
  business_area: string;
  ctry: string;
  exp: number;
  iat: number;
  iss: string;
  name: string;
  nbf: number;
  nonce: string;
  oid: string;
  preferred_username: string;
  rh: string;
  sub: string;
  tid: string;
  uti: string;
  ver: string;
};
