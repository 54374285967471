import { useAppContext } from '../../../../state/useAppProvider';
import { CustomDropdown, DropdownValue } from '../../../ custom-dropdown';
import { CloseIcon } from '../../../error-popup-handler/CloseIcon';
import { useTranslation } from 'react-i18next';
import { useProductHandler } from '../../../../state/useProductHandler';
import { useMessageHandlerContext } from '../../../../state/useMessageHandlerContext';
import { useMemo } from 'react';

export const ProductSelectorDropdownList = ({
  disabled,
  onDropdownOpen,
  menuPlacement = 'auto',
  hideDocumentPanelOnOpen = false,
}: {
  disabled: boolean;
  onDropdownOpen?: () => void;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  hideDocumentPanelOnOpen?: boolean;
}) => {
  const { t } = useTranslation();
  const { productFilter, currentChat } = useAppContext();
  const {
    filteredProductData,
    setProductFilterFromProduct,
    updateProductFilter,
    clearProductFilter,
    getProductFilterData,
    getProductFromId,
  } = useProductHandler();
  const { addProductSelection } = useMessageHandlerContext();

  const productFilterData = useMemo(
    () => getProductFilterData(productFilter),
    [getProductFilterData, productFilter],
  );

  const onProductSelect = (productChoice?: DropdownValue) => {
    const product = getProductFromId(productChoice?.value);
    if (currentChat.messages.length > 0 && product) {
      addProductSelection(product);
    } else {
      setProductFilterFromProduct(product);
    }
  };

  return (
    filteredProductData && (
      <div className='dropdown-container' data-disabled={disabled}>
        <CustomDropdown
          name={t('product_filter.brand')}
          options={filteredProductData.brands.map((brand) => ({
            value: brand.brand,
            label: brand.display_name,
          }))}
          onClick={onDropdownOpen}
          onChange={(value) => updateProductFilter('brand', value?.value)}
          value={productFilterData?.brand?.display_name}
          metricsId='BrandDropdown'
          menuPlacement={menuPlacement}
          toggleDocumentPanel={hideDocumentPanelOnOpen}
        />
        <CustomDropdown
          name={t('product_filter.product_group')}
          options={filteredProductData.product_groups.map((productGroup) => ({
            value: productGroup.product_group,
            label: productGroup.display_name,
          }))}
          onClick={onDropdownOpen}
          onChange={(value) =>
            updateProductFilter('product_group', value?.value)
          }
          value={productFilterData?.product_group?.display_name}
          metricsId='ProductGroupDropdown'
          menuPlacement={menuPlacement}
          toggleDocumentPanel={hideDocumentPanelOnOpen}
        />
        <CustomDropdown
          name={t('product_filter.product_name')}
          options={filteredProductData.product_names.map((productName) => ({
            value: productName.product_name,
            label: productName.display_name,
          }))}
          onClick={onDropdownOpen}
          onChange={onProductSelect}
          value={productFilterData?.product_name?.display_name}
          metricsId='ProductNameDropdown'
          menuPlacement={menuPlacement}
          toggleDocumentPanel={hideDocumentPanelOnOpen}
        />
        <button
          onClick={clearProductFilter}
          className='product-selector-clear-button'
          data-metrics-id='ClearProductSelectionButton'
        >
          {t('product_filter.clear_button_label')}
        </button>
      </div>
    )
  );
};
export const NavbarProductSelector = ({
  isOpen,
  disabled = false,
  onClose,
}: {
  isOpen: boolean;
  disabled?: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { filteredProductData } = useProductHandler();

  return (
    filteredProductData && (
      <div data-open={isOpen} className='navbar-product-selector'>
        <div className='product-filter-header-section'>
          <button
            aria-label={t('product_filter.close_button_label')}
            className='product-filter-close-button'
            onClick={onClose}
          >
            <CloseIcon />
          </button>
          <h3 className='product-filter-heading'>
            {t('product_filter.title')}
          </h3>
        </div>
        <ProductSelectorDropdownList disabled={disabled} />
      </div>
    )
  );
};
