import { createContext, useCallback, useEffect, useState, useRef } from 'react';
import { useApiContext } from '../api/useApiContext';
import { useAppContext } from './useAppProvider';
import { nanoid } from 'nanoid';
import {
  ChatMessage,
  Conversation,
  UserInteraction,
  Message,
  AIAnswerData,
  ToolAnswer,
  PlanningMessage,
  ConversationState,
  Subject,
  Instruction,
  InstructionType,
  ConversationStreamEventData,
  isConversationStreamEvent,
  RawStreamEvent,
  Product,
} from '../types';
import { useLoadingState } from './useLoadingState';
import { useAuthContext } from '../api/useAuthContext';
import { useTranslation } from 'react-i18next';
import { useProductHandler } from './useProductHandler';

export const MessageHandlerContext = createContext(
  {} as ReturnType<typeof useMessageHandler>,
);

export const MessageHandlerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const messageHandler = useMessageHandler();

  return (
    <MessageHandlerContext.Provider value={messageHandler}>
      {children}
    </MessageHandlerContext.Provider>
  );
};

const useMessageHandler = () => {
  const { t } = useTranslation();
  const { getConversationStream, historyUpdate } = useApiContext();
  const { currentChat, dispatch } = useAppContext();
  const { userSettings } = useAuthContext();
  const {
    setProductDataFromFilter,
    dataFiltersAreMatching,
    getProductsFromList,
    getSubjectFromId,
    mergeProductFilterAndSubjects,
    selectedProducts,
    updateProductChoices,
    setupRelatedProductData,
    addSubjectsToBeAddedToChoices,
    productData,
  } = useProductHandler();
  const [loadingAnswer, setLoadingAnswer] = useLoadingState();
  const { getSignedToolMessage } = useApiContext();
  const [pendingAnswer, setPendingAnswer] = useState<
    { message: ChatMessage; completed: boolean } | undefined
  >();
  const [gettingToolMessage, setGettingToolMessage] = useState(false);
  const initializedConversations = useRef<Set<string>>(new Set());

  useEffect(() => {
    if (!pendingAnswer) return;
    if (pendingAnswer.completed && !gettingToolMessage) {
      dispatch({
        type: 'ADD_PENDING_ANSWER_TO_CHAT',
        payload: pendingAnswer.message,
      });
      setPendingAnswer(undefined);
      setLoadingAnswer(false);
    } else {
      dispatch({
        type: 'SET_PENDING_ANSWER',
        payload: { type: 'text', messageContent: pendingAnswer.message },
      });
    }
  }, [dispatch, gettingToolMessage, pendingAnswer, setLoadingAnswer]);

  const updatePendingAnswer = useCallback(
    (messageData: Partial<ChatMessage>) => {
      setPendingAnswer((prev) => {
        if (!prev) {
          return {
            message: {
              id: nanoid(),
              date: new Date().toISOString(),
              role: 'assistant',
              content: '',
              ...messageData,
            },
            completed: false,
          };
        }
        return {
          message: {
            ...prev.message,
            ...messageData,
          },
          completed: prev.completed,
        };
      });
    },
    [],
  );

  const updatePendingAnswerContent = useCallback(
    (content: string, completed: boolean) => {
      setPendingAnswer((prev) => {
        if (!prev) {
          return {
            message: {
              id: nanoid(),
              date: new Date().toISOString(),
              role: 'assistant',
              content,
            },
            completed,
          };
        }
        return {
          message: {
            ...prev.message,
            content: prev.message.content + content,
          },
          completed,
        };
      });
    },
    [],
  );

  const addCitationsToPendingMessage = useCallback(
    (content: string) => {
      const { citations }: ToolAnswer = JSON.parse(content);
      setGettingToolMessage(true);
      getSignedToolMessage(citations).then((signedMessage) => {
        updatePendingAnswer({
          tool_message: signedMessage,
        });
        setGettingToolMessage(false);
      });
    },
    [getSignedToolMessage, updatePendingAnswer],
  );

  const addProductMessage = useCallback(
    (choices: Product[]) => {
      const productMessage: UserInteraction = {
        id: nanoid(),
        date: new Date().toISOString(),
        role: 'interaction',
        question: t('messages.product_choice.choice_prefix'),
        choices,
      };
      dispatch({ type: 'ADD_MESSAGE_TO_CHAT', payload: productMessage });
      return productMessage;
    },
    [dispatch, t],
  );

  const addAdditionalInfoMessage = useCallback(
    (answer: string) => {
      const instruction: ChatMessage = {
        id: nanoid(),
        role: 'assistant',
        content: answer,
        date: new Date().toISOString(),
      };
      dispatch({ type: 'ADD_MESSAGE_TO_CHAT', payload: instruction });
    },
    [dispatch],
  );

  const handleAIAnswerEvent = useCallback(
    (data: AIAnswerData) => {
      switch (data.role) {
        case 'assistant':
          updatePendingAnswerContent(data.content, data.completed ?? false);
          break;
        case 'tool':
          addCitationsToPendingMessage(data.content);
          break;
        default:
          throw new Error('Invalid role in AI answer event.');
      }
    },
    [addCitationsToPendingMessage, updatePendingAnswerContent],
  );

  const handlePlanningEvent = useCallback(
    (data: PlanningMessage) => {
      dispatch({ type: 'ADD_MESSAGE_TO_CHAT', payload: data });
      dispatch({
        type: 'SET_CONVERSATION_STATE',
        payload: data.planning.tool,
      });
    },
    [dispatch],
  );

  const handleSubjectsEvent = useCallback(
    (subjects: Subject[], currentMessages: Message[]) => {
      dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: { subjects } });
      const latestProductMessage = currentMessages
        .filter((message) => message.role === 'interaction')
        .pop();

      if (!latestProductMessage) {
        const products = getProductsFromList(subjects);
        addProductMessage(products);
      }
    },
    [dispatch, getProductsFromList, addProductMessage],
  );

  const handleRelatedSubjectsEvent = useCallback(
    (products: Product[]) => {
      setupRelatedProductData(products);
    },
    [setupRelatedProductData],
  );

  const handleInstructionEvent = useCallback(
    (instruction: Instruction) => {
      switch (instruction.action) {
        case InstructionType.PRODUCT_SELECTION: {
          if (instruction.options) {
            const options = getProductsFromList(instruction.options);
            if (options.length > 0) {
              updateProductChoices(options);
            } else {
              addSubjectsToBeAddedToChoices(instruction.options);
            }
          }
          break;
        }
        case InstructionType.ADDITIONAL_INFORMATION:
          addAdditionalInfoMessage(t('messages.need_more_info'));
          break;
      }
    },
    [
      addAdditionalInfoMessage,
      t,
      getProductsFromList,
      updateProductChoices,
      addSubjectsToBeAddedToChoices,
    ],
  );

  const handleStreamEventData = useCallback(
    (
      streamEvents: ConversationStreamEventData[],
      conversation: Conversation,
    ) => {
      for (const eventData of streamEvents) {
        switch (eventData.event) {
          case 'conversation':
          case 'citations':
            handleAIAnswerEvent(eventData.data);
            break;
          case 'planning':
            handlePlanningEvent(eventData.data);
            break;
          case 'subjects':
            handleSubjectsEvent(eventData.data, conversation.messages);
            break;
          case 'related_subjects':
            handleRelatedSubjectsEvent(eventData.data);
            break;
          case 'instruction':
            handleInstructionEvent(eventData.data);
            break;
          default:
            throw new Error(
              'Invalid stream event type received. Check backend implementation.',
            );
        }
      }
    },
    [
      handleAIAnswerEvent,
      handleInstructionEvent,
      handlePlanningEvent,
      handleRelatedSubjectsEvent,
      handleSubjectsEvent,
    ],
  );

  const rawStreamEventMapper = (eventString: string) => {
    const eventLines = eventString.split('\n');
    return eventLines.reduce((acc, line) => {
      const [key, value] = line.split(/: (.+)/); // Split only at the first occurrence of ": "
      switch (key) {
        case 'event':
          if (isConversationStreamEvent(value)) {
            acc[key] = value;
          }
          break;
        case 'data':
          acc[key] = value;
          break;
        case 'id':
          acc[key] = value;
          break;
      }
      return acc;
    }, {} as RawStreamEvent);
  };

  const getPatchedRawEvent = (
    previousFragment: RawStreamEvent,
    newDataChunk: string,
  ): RawStreamEvent => {
    const patchedEvent = { ...previousFragment };
    patchedEvent.data += newDataChunk;
    return patchedEvent;
  };

  const handleStream = useCallback(
    async (stream: ReadableStream<Uint8Array>, conversation: Conversation) => {
      const reader = stream.getReader();

      // The previousFragment is used to store the last fragment in case a JSON object received is split between two chunks.
      const readChunk = async (previousFragment?: RawStreamEvent) => {
        await reader
          .read()
          .then(async ({ done, value }) => {
            if (done) {
              return;
            }

            const decoder = new TextDecoder('utf-8');
            const chunk = decoder.decode(value, { stream: true });
            let eventChunks = chunk
              .split('\n\n')
              .filter((event) => event.length > 0);
            let patchedEvent: RawStreamEvent | undefined;

            // If previous chunk was fragmented, patch it with the first chunk of this recursion step, and update the eventChunks array.
            if (previousFragment) {
              patchedEvent = getPatchedRawEvent(
                previousFragment,
                eventChunks[0],
              ); // Patch the previous fragmented data with the first chunk, as it will contain the rest of the JSON object.
              eventChunks = eventChunks.slice(1); // Remove the first chunk, as it has been patched to the previous fragment.
            }
            const rawEventData: RawStreamEvent[] =
              eventChunks.map(rawStreamEventMapper);

            // Combine the patched event with the rest of the raw event data.
            const completeRawData = patchedEvent
              ? [patchedEvent, ...rawEventData]
              : rawEventData;

            const events: ConversationStreamEventData[] = [];

            //1. Try to iterate the raw event data and produce parsed events.
            //2. If the JSON parsing fails, store the fragment and wait for the next chunk.
            for (const event of completeRawData) {
              try {
                const data = JSON.parse(event.data);
                events.push({
                  id: event.id,
                  event: event.event,
                  data,
                });
              } catch (err: unknown) {
                return await readChunk(event);
              }
            }

            handleStreamEventData(events, conversation);

            await readChunk();
          })
          .catch((err: unknown) => {
            console.error('Failed to read chunk', err);
          });
      };

      await readChunk();
    },
    [handleStreamEventData],
  );

  const startStream = useCallback(
    async (conversation: Conversation) => {
      setLoadingAnswer(true, t('messages.loading_answer'));
      dispatch({
        type: 'SET_CONVERSATION_STATE',
        payload: ConversationState.UNKNOWN,
      });
      const stream = await getConversationStream(conversation);
      if (stream) {
        await handleStream(stream, conversation);
      }
      !gettingToolMessage && setLoadingAnswer(false);
    },
    [
      dispatch,
      getConversationStream,
      handleStream,
      setLoadingAnswer,
      t,
      gettingToolMessage,
    ],
  );

  const shouldRespondToMessageUpdate = (messages: Message[]) => {
    if (messages.length === 0) return false;
    const latestMessage = messages[messages.length - 1];

    switch (latestMessage.role) {
      case 'assistant':
        return false;
      case 'system':
      case 'interaction':
      case 'user':
        return true;
    }
  };

  const respondToMessageUpdate = useCallback(
    async (update: Partial<Conversation>) => {
      const conversation = { ...currentChat, ...update };
      if (!shouldRespondToMessageUpdate(conversation.messages)) return;
      await startStream(conversation);
    },
    [currentChat, startStream],
  );

  const addNewMessage = useCallback(
    (newUserMessage: ChatMessage | UserInteraction) => {
      const messages = [...currentChat.messages, newUserMessage];
      const user_specified = mergeProductFilterAndSubjects(
        currentChat.user_specified,
      );
      const chatUpdate: Partial<Conversation> =
        messages.length === 1
          ? {
              ...currentChat,
              id: nanoid(),
              created_at: new Date().toISOString(),
              updated_at: new Date().toISOString(),
              messages,
              user_specified,
            }
          : {
              ...currentChat,
              updated_at: new Date().toISOString(),
              messages,
              user_specified,
            };
      dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: chatUpdate });
      respondToMessageUpdate(chatUpdate);
    },
    [
      currentChat,
      dispatch,
      mergeProductFilterAndSubjects,
      respondToMessageUpdate,
    ],
  );

  const addProductSelection = useCallback(
    (product: Product) => {
      const subject = getSubjectFromId(product.product_name);
      if (!subject) {
        console.error('Could not find subject for product', product);
        return;
      }
      const user_specified = currentChat.user_specified
        ? [...currentChat.user_specified, subject]
        : [subject];
      const products = selectedProducts
        ? [...selectedProducts, product]
        : [product];
      const productMessage = addProductMessage(products);

      const chatUpdate: Partial<Conversation> = {
        ...currentChat,
        user_specified,
        messages: [...currentChat.messages, productMessage],
      };

      dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: chatUpdate });
      respondToMessageUpdate(chatUpdate);
      updateProductChoices(undefined);
    },
    [
      addProductMessage,
      currentChat,
      dispatch,
      getSubjectFromId,
      respondToMessageUpdate,
      selectedProducts,
      updateProductChoices,
    ],
  );

  const createNewChat = useCallback(() => {
    if (!userSettings) return;
    dispatch({ type: 'CREATE_NEW_CHAT', payload: userSettings });
    updateProductChoices(undefined);
    if (!dataFiltersAreMatching()) {
      setProductDataFromFilter(userSettings.data_filter);
    }
  }, [
    dataFiltersAreMatching,
    dispatch,
    setProductDataFromFilter,
    userSettings,
    updateProductChoices,
  ]);

  // Update chat history when chat is updated.
  useEffect(() => {
    if (currentChat.messages.length === 0 || !currentChat.id) return;
    historyUpdate(currentChat);
  }, [currentChat, historyUpdate]);

  const initializeConversation = useCallback(
    (conversation: Conversation) => {
      let latestConversationState: ConversationState | undefined;
      let latestProductChoices: Product[] | undefined;

      conversation.messages.forEach((message) => {
        if (
          message.role === 'system' &&
          'planning' in message &&
          (message as PlanningMessage).planning
        ) {
          const planningMessage = message as PlanningMessage;
          latestConversationState = planningMessage.planning.tool;
          if (planningMessage.planning.subjects) {
            const options = getProductsFromList(planningMessage.planning.subjects);
            latestProductChoices = options;
          }
        }
        if ('instruction' in message && (message as any).instruction) {
          const instructionMessage = message as any;
          const instruction = instructionMessage.instruction as Instruction;

          if (
            instruction.action === InstructionType.PRODUCT_SELECTION &&
            instruction.options
          ) {
            const options = getProductsFromList(instruction.options);
            latestProductChoices = options;
          }
        }
      });

      if (latestConversationState) {
        dispatch({
          type: 'SET_CONVERSATION_STATE',
          payload: latestConversationState,
        });
      }
      if (latestProductChoices && latestProductChoices.length > 0) {
        updateProductChoices(latestProductChoices);
      } else if (conversation.subjects && conversation.subjects.length > 0) {
        const products = getProductsFromList(conversation.subjects);
        if (products && products.length > 0) {
          updateProductChoices(products);
        } else {
          console.log('No product choices found in conversation subjects.');
        }
      } else {
        console.log('No product choices to update.');
      }
    },
    [dispatch, getProductsFromList, updateProductChoices, productData],
  );
  useEffect(() => {
    if (
      currentChat &&
      currentChat.id &&
      productData &&
      !initializedConversations.current.has(currentChat.id)
    ) {
      initializeConversation(currentChat);
      initializedConversations.current.add(currentChat.id);
    }
  }, [currentChat, productData, initializeConversation]);

  return {
    loadingState: loadingAnswer,
    createNewChat,
    addNewMessage,
    respondToMessageUpdate,
    addProductMessage,
    addProductSelection,
  };
};
