import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  BusinessSegment,
  IdTokenPayload,
  Name,
  UserData,
  UserSettings,
  UserSetupData,
} from '../types';
import { useMsal } from '@azure/msal-react';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { jwtDecode } from 'jwt-decode';
import { endpoints } from './constants';
import { axiosWithToken } from './utils/axiosWithToken';
import { useAppContext } from '../state/useAppProvider';
import { useTranslation } from 'react-i18next';

export const AuthContext = createContext(
  {} as ReturnType<typeof useProvideAuth>,
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useProvideAuth();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const useProvideAuth = () => {
  const { i18n } = useTranslation();
  const { instance, inProgress, accounts } = useMsal();
  const { dispatch } = useAppContext();
  const [idToken, setIdToken] = useState<string | null>(null);
  const [userSetupData, setUserSetupData] = useState<UserSetupData | null>(
    null,
  );
  const [userData, setUserData] = useState<UserData | undefined>();
  const userSettings = useMemo(
    () => userData?.userSettings,
    [userData?.userSettings],
  );
  const [tokenExpiry, setTokenExpiry] = useState(0);
  const [authLoading, setAuthLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [savingSettings, setSavingSettings] = useState(false);
  const isAuthenticated = !!idToken;
  const userIsNotSetup = !userSettings && !loadingUser && isAuthenticated;

  const logout = useCallback(async () => {
    const logoutRequest = {
      account: accounts[0],
    };
    instance.logoutRedirect(logoutRequest);
  }, [accounts, instance]);

  const updateIdTokenState = (idToken: string) => {
    const { exp } = jwtDecode<IdTokenPayload>(idToken);
    setTokenExpiry(exp);
    setIdToken(idToken);
  };

  const getUpdatedIdToken = useCallback(async () => {
    const tokenHasExpired = Date.now() >= tokenExpiry * 1000;
    const accessTokenRequest = {
      scopes: ['profile', 'openid'],
      account: accounts[0],
      forceRefresh: tokenHasExpired,
    };

    const idToken = await instance
      .acquireTokenSilent(accessTokenRequest)
      .then(function (accessTokenResponse) {
        updateIdTokenState(accessTokenResponse.idToken);
        return accessTokenResponse.idToken;
      })
      .catch((error) => {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(accessTokenRequest);
        }
        console.error(error);
      });

    return idToken;
  }, [accounts, instance, tokenExpiry]);

  const axiosInstance = useMemo(
    () => axiosWithToken(getUpdatedIdToken),
    [getUpdatedIdToken],
  );

  const setUserState = useCallback(
    (userData: UserData) => {
      setUserData(userData);
      dispatch({
        type: 'UPDATE_CURRENT_CHAT',
        payload: { region: userData.userSettings.region },
      });
    },
    [dispatch],
  );

  const getUserSettings = useCallback(
    async (userId?: string) => {
      const id = userId ?? userSettings?.user_id;
      if (!id) return null;
      setLoadingUser(true);
      return await axiosInstance
        .get<UserSettings>(`${endpoints.user}/${id}`, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          setLoadingUser(false);
          return res.data;
        })
        .catch((err) => {
          console.error('Failed to get user settings', err);
          setLoadingUser(false);
          return null;
        });
    },
    [axiosInstance, userSettings?.user_id],
  );

  const updateUserSettings = useCallback(
    async (userInfo: UserSettings, updateUserState?: boolean) => {
      setSavingSettings(true);
      const response = await axiosInstance
        .post<UserSettings>(endpoints.user, userInfo, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          updateUserState &&
            setUserData((prev) => {
              return prev && { ...prev, userSettings: res.data };
            });
          return res.data;
        })
        .catch((err) => {
          console.error('Failed to set user settings', err);
          return null;
        });
      setSavingSettings(false);
      return response;
    },
    [axiosInstance],
  );

  const getBusinessSegmentFromArea = (businessArea?: string) => {
    if (businessArea?.toUpperCase().includes('BSI')) return BusinessSegment.BSI;
    if (businessArea?.toUpperCase().includes('BSP')) return BusinessSegment.BSP;
  };

  const addUser = useCallback(
    (id: string, name: Name, userSettings: UserSettings) => {
      setLoadingUser(true);
      updateUserSettings(userSettings).then((res) => {
        setUserState({
          name,
          id,
          userSettings: res ?? userSettings,
        });
        setLoadingUser(false);
      });
    },
    [updateUserSettings, setUserState],
  );

  const nameFromTokenName = (reversedName: string): Name => {
    const nameArray = reversedName.split(', ').reverse();
    const { firstName, lastName } = nameArray.reduce(
      (acc, name, i) => {
        if (i === 0) acc.firstName = name;
        if (i === 1) acc.lastName = name;
        return acc;
      },
      { firstName: '', lastName: '' },
    );
    const fullName = nameArray.join(' ');
    const initials = fullName
      .split(' ')
      .map((n) => n[0])
      .join('');

    return { fullName, firstName, lastName, initials };
  };

  const setUserFromToken = useCallback(
    async (idToken: string) => {
      const {
        preferred_username: email,
        business_area,
        name: reversedName,
        sub: id,
      } = jwtDecode<IdTokenPayload>(idToken);
      const name = nameFromTokenName(reversedName);
      const business_segment = getBusinessSegmentFromArea(business_area);

      const userSettings = await getUserSettings(id);

      if (userSettings && userSettings.user_setup_complete) {
        //Making sure user settings match the business area in the token.
        if (
          userSettings.business_area !== business_area ||
          (business_segment &&
            userSettings.business_segment !== business_segment)
        ) {
          const updatedSettings = {
            ...userSettings,
            business_area,
            business_segment,
          };

          setUserState({
            name,
            id,
            userSettings: updatedSettings,
          });
          updateUserSettings(updatedSettings);
        } else {
          setUserState({
            name,
            id,
            userSettings,
          });
        }
      } else {
        // If user is not setup, we set the userSetupData state to be used in the setup form.
        setUserSetupData({
          id,
          email,
          name,
          business_area,
          business_segment,
        });
      }
    },
    [getUserSettings, setUserState, updateUserSettings],
  );

  // Initializes the user data from the ID token given from the msal instance
  useEffect(() => {
    if (authLoading || initialized) return;
    const accessTokenRequest = {
      scopes: ['profile', 'openid'],
      account: accounts[0],
      forceRefresh: true,
    };
    if (inProgress === InteractionStatus.None) {
      setAuthLoading(true);
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((res) => {
          updateIdTokenState(res.idToken);
          setUserFromToken(res.idToken);
          setAuthLoading(false);
          setInitialized(true);
        })
        .catch((error) => {
          setAuthLoading(false);
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
          console.error(error);
        });
    }
  }, [
    accounts,
    authLoading,
    inProgress,
    initialized,
    instance,
    setUserFromToken,
  ]);

  //Update i18n language whenever userSettings.language changes.
  useEffect(() => {
    if (
      !userSettings?.language ||
      i18n.resolvedLanguage === userSettings.language
    )
      return;
    i18n.changeLanguage(userSettings.language);
  }, [userSettings?.language, i18n]);

  return {
    idToken,
    logout,
    authLoading,
    loadingUser,
    userIsNotSetup,
    userSetupData,
    savingSettings,
    getUpdatedIdToken,
    axiosInstance,
    getUserSettings,
    updateUserSettings,
    userSettings,
    userData,
    addUser,
  };
};
