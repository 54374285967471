import { ProductSelectorDropdownList } from '..';
import './ProductSelectionMenu.scss';
import { useTranslation } from 'react-i18next';
import { useProductHandler } from '../../../../state/useProductHandler';
import { Product } from '../../../../types';
import { useMessageHandlerContext } from '../../../../state/useMessageHandlerContext';
import { useAppContext } from '../../../../state/useAppProvider';

export const ProductSelectionMenu = () => {
  const { t } = useTranslation();
  const { dispatch } = useAppContext();
  const { addProductSelection } = useMessageHandlerContext();
  const { getProductSubheader, canAddNewProduct, productChoices } =
    useProductHandler();

  const scrollToBottom = () => {
    const anchor = document.querySelector('#bottom-scroll-anchor');
    //Using timeout as scroll height has not properly updated when this is called.
    setTimeout(
      () =>
        anchor?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start',
        }),
      50,
    );
  };

  const onDropdownOpen = () => {
    scrollToBottom();
    dispatch({ type: 'SET_HIDE_DOCUMENT_PANEL', payload: true });
  };

  const selectProduct = (product: Product) => {
    addProductSelection(product);
  };

  if (!productChoices || !canAddNewProduct) return null;

  return (
    <div className='product-choice-menu'>
      <h4 className='product-choice-instructions'>
        {t('messages.product_choice.question')}
      </h4>
      <p className='product-section-header'>
        {t('messages.product_choice.relevant_heading')}
      </p>
      <div className='product-choice-list'>
        {productChoices?.slice(0, 6).map((product) => (
          <button
            data-metrics-id={`ProductChoice-${product.product_name}`}
            key={Object.values(product).join('-')}
            onClick={() => {
              selectProduct(product);
            }}
            className='product-choice'
          >
            <h4 className='product-choice-subheader'>
              {getProductSubheader(product)}
            </h4>
            <h5 className='product-choice-header'>{product.display_name}</h5>
          </button>
        ))}
      </div>
      <p className='product-section-header'>
        {t('messages.product_choice.all_heading')}
      </p>
      <ProductSelectorDropdownList
        onDropdownOpen={onDropdownOpen}
        disabled={false}
        menuPlacement='top'
        hideDocumentPanelOnOpen
      />
    </div>
  );
};
