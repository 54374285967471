import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguagueDetector from 'i18next-browser-languagedetector';
import cs from './locales/cs-CZ/translation.json';
import en from './locales/en-US/translation.json';
import da from './locales/da-DK/translation.json';
import de from './locales/de-DE/translation.json';
import es from './locales/es-ES/translation.json';
import fi from './locales/fi-FI/translation.json';
import fr from './locales/fr-FR/translation.json';
import it from './locales/it-IT/translation.json';
import nb from './locales/nb-NO/translation.json';
import nl from './locales/nl-NL/translation.json';
import pl from './locales/pl-PL/translation.json';
import pt from './locales/pt-PT/translation.json';
import sv from './locales/sv-SE/translation.json';

i18next
  .use(initReactI18next)
  .use(LanguagueDetector)
  .init({
    fallbackLng: 'en-US',
    debug: import.meta.env.DEV,
    //debug: false,
    resources: {
      'cs-CZ': {
        translation: cs,
      },
      'da-DK': {
        translation: da,
      },
      'es-ES': {
        translation: es,
      },
      'fi-FI': {
        translation: fi,
      },
      'it-IT': {
        translation: it,
      },
      'en-US': {
        translation: en,
      },
      'de-DE': {
        translation: de,
      },
      'fr-FR': {
        translation: fr,
      },
      'nb-NO': {
        translation: nb,
      },
      'nl-NL': {
        translation: nl,
      },
      'pl-PL': {
        translation: pl,
      },
      'pt-PT': {
        translation: pt,
      },
      'sv-SE': {
        translation: sv,
      },
    },
  });
