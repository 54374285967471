import { useCallback, useState } from 'react';
import { useAuthContext } from '../../api/useAuthContext';
import {
  BusinessSegment,
  BusinessSegmentKey,
  Language,
  Region,
  UserSettings,
  languages,
} from '../../types';
import { CloseIcon } from '../../components/error-popup-handler/CloseIcon';
import './Settings.scss';
import {
  CustomDropdown,
  DropdownValue,
} from '../../components/ custom-dropdown';
import { ChatLoader } from '../../components/chat/chat-loader';
import { useAppContext } from '../../state/useAppProvider';
import { useTranslation } from 'react-i18next';
import { useNavigateWithTracker } from '../../hooks/use-navigate-with-tracker';
import { Pages } from '../constants/pages';

export const ToggleSelect = ({
  choice,
  options,
  onSelect,
  metricsId,
  expandToFit = false,
  upperCase = true,
}: {
  choice: string;
  options: string[];
  onSelect: (choice: string) => void;
  metricsId?: string;
  expandToFit?: boolean;
  upperCase?: boolean;
}) => {
  return (
    <div className='toggle-select' data-expand-to-fit={expandToFit}>
      {options.map((option, index) => (
        <button
          data-metrics-id={`Toggle-${metricsId}-${option}`}
          key={index}
          onClick={() => onSelect(option)}
          data-selected={option === choice}
          className='toggle-option'
        >
          {upperCase ? option.toUpperCase() : option}
        </button>
      ))}
    </div>
  );
};

export const Settings = ({ userSettings }: { userSettings: UserSettings }) => {
  const { t } = useTranslation();
  const { updateUserSettings, savingSettings } = useAuthContext();
  const { currentChat } = useAppContext();
  const [selectedUserSettings, setSelectedUserSettings] =
    useState(userSettings);
  const navigate = useNavigateWithTracker();

  const returnToChat = () => {
    if (currentChat.id) {
      navigate(Pages.CHAT, `/chat/${currentChat.id}`);
    } else {
      navigate(Pages.HOME, '/');
    }
  };

  const cancel = () => {
    setSelectedUserSettings({
      user_id: userSettings.user_id,
      email: userSettings.email,
      region: userSettings.region,
      business_segment: userSettings.business_segment,
      business_area: userSettings.business_area,
      data_filter: userSettings.data_filter,
      language: userSettings.language,
    });
    returnToChat();
  };

  const save = async () => {
    await updateUserSettings(selectedUserSettings, true);
    returnToChat();
  };

  const setRegion = (region: string) => {
    setSelectedUserSettings({
      ...selectedUserSettings,
      region: region as Region,
    });
  };

  const setDataFilter = (dataFilter: string) => {
    setSelectedUserSettings({
      ...selectedUserSettings,
      data_filter: [BusinessSegment[dataFilter as BusinessSegmentKey]],
    });
  };

  const setLanguage = useCallback(
    (language?: DropdownValue) => {
      setSelectedUserSettings((currentUserSettings) => {
        return {
          ...currentUserSettings,
          language: language
            ? (language.value as Language)
            : userSettings.language,
        };
      });
    },
    [userSettings.language],
  );

  return savingSettings ? (
    <ChatLoader message={t('settings.saving')} />
  ) : (
    <div className='settings-container'>
      <div className='settings-header-section'>
        <h2 className='settings-header'>{t('settings.title')}</h2>
        <button
          data-metrics-id='CloseSettingsButton'
          aria-label={t('settings.close')}
          onClick={cancel}
          className='btn-badge settings-close-button'
        >
          <CloseIcon />
        </button>
      </div>
      <div className='settings-content'>
        <div className='settings-item'>
          <h3 className='settings-label'>{t('settings.region')}</h3>
          <ToggleSelect
            metricsId='Region'
            choice={selectedUserSettings.region}
            options={Object.values(Region)}
            onSelect={setRegion}
          />
        </div>
        <div className='settings-item'>
          <h3 className='settings-label'>{t('settings.business_segment')}</h3>
          {/* TODO: Add different component to handle multiple data filters when/if available. */}
          <ToggleSelect
            metricsId='BusinessSegment'
            choice={
              selectedUserSettings.data_filter?.[0] ?? BusinessSegment.BSP
            }
            options={Object.values(BusinessSegment)}
            onSelect={setDataFilter}
          />
        </div>
        <div className='settings-item'>
          <h3 className='settings-label'>{t('settings.language')}</h3>
          <CustomDropdown
            metricsId='LanguageSelect'
            name={t('settings.language')}
            onChange={setLanguage}
            options={Object.entries(languages).map(([code, name]) => ({
              value: code,
              label: name,
            }))}
            value={languages[selectedUserSettings.language]}
            useFloatingLabel={false}
          />
        </div>
      </div>
      <div className='settings-footer'>
        <button
          data-metrics-id='CancelSettingsButton'
          onClick={cancel}
          className='settings-cancel-button'
        >
          {t('settings.cancel')}
        </button>
        <button
          data-metrics-id='SaveSettingsButton'
          onClick={save}
          className='settings-cancel-button'
        >
          {t('settings.save')}
        </button>
      </div>
    </div>
  );
};

export const SettingsPage = () => {
  const { t } = useTranslation();
  const { userSettings, loadingUser } = useAuthContext();
  return loadingUser ? (
    <ChatLoader message={t('settings.loading')} />
  ) : (
    <>{userSettings && <Settings userSettings={userSettings} />}</>
  );
};
