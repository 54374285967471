import { useEffect, useRef, useState } from 'react';
import {
  BusinessSegment,
  Language,
  languages,
  Name,
  Region,
  roles,
  UserSettings,
  UserSetupData,
} from '../../types';
import { SelectInstance } from 'react-select';
import { useTranslation } from 'react-i18next';
import {
  CustomDropdown,
  DropdownValue,
  InputValidationState,
} from '../ custom-dropdown';
import { ToggleSelect } from '../../pages/settings/Settings';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../api/useAuthContext';
import './UserSetupWizard.scss';
import { UserCompleteIcon } from './UserCompleteIcon';
import { generateYears } from './generate-years';

type StepProps = {
  current: boolean;
  onValueChange: (data: Partial<UserSettings>) => void;
  setupSettings: UserSettings;
};

type ExperienceValidationData = {
  role: InputValidationState;
  experience: InputValidationState;
};

const IntroStep = ({ current, name }: { current: boolean; name: string }) => {
  const { t } = useTranslation();
  return (
    <div
      data-current={current}
      className='account-wizard-step'
      {...{ inert: !current ? '' : undefined }}
    >
      <div className='account-wizard-substep'>
        <h3 className='account-wizard-main-heading'>
          {t('intro_wizard.greetings_header', { name })}
        </h3>
        <p className='account-wizard-intro-text'>
          {t('intro_wizard.greetings_body')}
        </p>
        <div className='account-wizard-info-container'>
          <p className='account-wizard-info-paragraph'>
            {t('intro_wizard.greetings_info')}
          </p>
        </div>
      </div>
    </div>
  );
};

const LanguageStep = ({
  current,
  onValueChange,
}: Omit<StepProps, 'setupSettings'>) => {
  const inputRef = useRef<SelectInstance<DropdownValue>>(null);
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState<
    DropdownValue | undefined
  >();

  // Focus the dropdown after the transition of the step is done.
  useEffect(() => {
    if (current) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 500);
    }
  }, [current]);

  const handleLangChange = (language?: DropdownValue) => {
    const selectedLang = language ? (language.value as Language) : 'en-US';
    onValueChange({
      language: selectedLang,
    });
    i18n.changeLanguage(selectedLang);
    setSelectedLanguage(language);
  };

  return (
    <div
      data-current={current}
      className='account-wizard-step'
      {...{ inert: !current ? '' : undefined }}
    >
      <div className='account-wizard-substep'>
        <h3 className='account-wizard-heading'>Choose your language</h3>
        <p className='account-setup-intro-text'>
          Please select your preferred language.
        </p>
        <CustomDropdown
          ref={inputRef}
          metricsId='LanguageSelect'
          name={t('settings.language')}
          onChange={handleLangChange}
          options={Object.entries(languages).map(([code, name]) => ({
            value: code,
            label: name,
          }))}
          value={selectedLanguage?.label ?? languages['en-US']}
          useFloatingLabel={false}
          absolutePosition={false}
          compactHeight
        />
      </div>
    </div>
  );
};

const RegionStep = ({
  current,
  onValueChange,
  setupSettings: currentSetupSettings,
}: StepProps) => {
  const { t } = useTranslation();
  const regionLabels = [
    t('intro_wizard.region.eu'),
    t('intro_wizard.region.na'),
  ];
  return (
    <div
      data-current={current}
      className='account-wizard-step'
      {...{ inert: !current ? '' : undefined }}
    >
      <div className='account-wizard-substep'>
        <h3 className='account-wizard-heading'>
          {t('intro_wizard.region.title')}
        </h3>
        <p className='account-setup-intro-text'>
          {t('intro_wizard.region.description')}
        </p>
        <ToggleSelect
          metricsId='Region'
          choice={
            currentSetupSettings.region === Region.EU
              ? regionLabels[0]
              : regionLabels[1]
          }
          options={regionLabels}
          onSelect={(choice) => {
            onValueChange({
              region: choice === regionLabels[0] ? Region.EU : Region.NA,
            });
          }}
          expandToFit
          upperCase={false}
        />
      </div>
    </div>
  );
};

const BusinessSegmentStep = ({
  current,
  onValueChange,
  setupSettings: currentSetupSettings,
}: StepProps) => {
  const { t } = useTranslation();
  const businessSegmentDisplayNames = {
    [BusinessSegment.BSI]: 'Business Segment Industrial',
    [BusinessSegment.BSP]: 'Business Segment Pedestrian',
  };
  const businessSegmentOptions = Object.values(BusinessSegment).map((segment) => ({
    value: segment, 
    label: businessSegmentDisplayNames[segment], 
  }));
  return (
    <div
      data-current={current}
      className='account-wizard-step'
      {...{ inert: !current ? '' : undefined }}
    >
      <div className='account-wizard-substep'>
        <h3 className='account-wizard-heading'>
          {t('business_segment_heading')}
        </h3>
        <p className='account-setup-intro-text'>
          {t('business_segment_intro_text')}
        </p>
        <ToggleSelect
          metricsId='BusinessSegment'
          choice={businessSegmentDisplayNames[currentSetupSettings.data_filter[0]]}
          options={businessSegmentOptions.map((option) => option.label)}
          onSelect={(choice) => {
            const selectedValue = businessSegmentOptions.find((option) => option.label === choice)?.value;
            onValueChange({ data_filter: [selectedValue as BusinessSegment] });
          }}
          expandToFit
          upperCase={false}
        />
      </div>
    </div>
  );
};

const ExperienceStep = ({
  current,
  onValueChange,
  setupSettings,
  validationData,
}: StepProps & { validationData: ExperienceValidationData }) => {
  const { t } = useTranslation();
  const firstInputRef = useRef<SelectInstance<DropdownValue>>(null);
  const yearOptions = generateYears();
  const roleOptions = roles.map((role) => ({
    value: role.value,
    label: role.display_name,
  }));

  useEffect(() => {
    if (current) {
      setTimeout(() => {
        firstInputRef.current?.focus();
      }, 500);
    }
  }, [current]);

  return (
    <div
      data-current={current}
      className='account-wizard-step'
      {...{ inert: !current ? '' : undefined }}
    >
      <div className='account-wizard-substep'>
        <h3 className='account-wizard-heading'>
          {t('setup.roleHeading', 'Role')}
        </h3>
        <p className='account-setup-intro-text'>
          {t('setup.roleIntro', 'What is your role at ASSA ABLOY?')}
        </p>
        <CustomDropdown
          ref={firstInputRef}
          name='Role'
          validated={validationData.role}
          options={roleOptions}
          onChange={(role) => {
            onValueChange({
              role: role?.label,
            });
          }}
          value={
            roleOptions.find((option) => option.label === setupSettings.role)
              ?.label
          }
          useFloatingLabel={false}
          absolutePosition={false}
          compactHeight
        />
      </div>

      <div className='account-wizard-substep'>
        <h3 className='account-wizard-heading'>
          {t('setup.experienceLevelHeading', 'Experience Level')}
        </h3>
        <p className='account-setup-intro-text'>
          {t(
            'setup.experienceLevelIntro',
            'Please select your experience level.',
          )}
        </p>
        <CustomDropdown
          name='YearOfEmployment'
          validated={validationData.experience}
          options={yearOptions}
          onChange={(year) => {
            onValueChange({
              year_of_employment: year?.value,
            });
          }}
          value={setupSettings.year_of_employment}
          useFloatingLabel={false}
          absolutePosition={false}
          compactHeight
        />
      </div>
    </div>
  );
};

//Disclaimer for the user
const DisclaimerStep = ({
  current,
  onValueChange,
}: Omit<StepProps, 'setupSettings'>) => {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    onValueChange({ accepted_ai_use_disclaimer: checked });
  };
  //TODO: Remove lng: 'en' from all t calls when translations are validated.
  const bullets = t('disclaimer.points', { returnObjects: true });
    // { returnObjects: true, lng: 'en' });

  return (
    <div
      data-current={current}
      className='account-wizard-step'
      {...{ inert: !current ? '' : undefined }}
    >
      <div className='account-wizard-substep'>
        <h3 className='account-wizard-heading'>
          {t('disclaimer.heading')}
        </h3>
        <p className='account-wizard-disclaimer-text'>
          {t('disclaimer.ingress')}
        </p>
        <ol className='disclaimer-list'>
          {bullets.map((bullet, index) => (
            <li key={index}>
              <h4>{bullet.title}</h4>
              <p dangerouslySetInnerHTML={{ __html: bullet.text }}></p>
            </li>
          ))}
        </ol>
        <p className='account-wizard-disclaimer-text'>
          {t('disclaimer.conclusion')}
        </p>
        <a
          className='disclaimer-link'
          href='https://avenue.assaabloy.net/group-library/health-and-safety'
          target='_blank'
        >
          {t('disclaimer.conclusion_link', { lng: 'en' })}
        </a>
        <label className='disclaimer-checkbox'>
          <input
            type='checkbox'
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          {t('disclaimer.accept_text')}
        </label>
      </div>
    </div>
  );
};

const SetupCompleteStep = () => {
  const { t } = useTranslation();
  return (
    <div className='account-wizard-complete'>
      <UserCompleteIcon />
      <h3 className='account-wizard-main-heading'>
        {t('account_setup_complete.heading')}
      </h3>
      <p className='account-setup-complete-text'>
        {t('account_setup_complete.body')}
      </p>
      <Link to='/' className='account-wizard-complete-button'>
        {t('account_setup_complete.button_text')}
      </Link>
    </div>
  );
};

export const UserSetupWizard = ({
  userSetupData,
  onSave,
}: {
  userSetupData: UserSetupData;
  onSave: (id: string, name: Name, setupFormSettings: UserSettings) => void;
}) => {
  const { userIsNotSetup } = useAuthContext();
  const wizardContainerRef = useRef<HTMLDivElement>(null);
  const [currentStep, setCurrentStep] = useState(0);
  // We initialize the setup form data with the user data from token as a default.
  const [setupFormData, setSetupFormData] = useState<UserSettings>({
    user_id: userSetupData.id,
    language: 'en-US',
    region: Region.EU,
    email: userSetupData.email,
    business_area: userSetupData.business_area,
    data_filter: [BusinessSegment.BSP],
    accepted_ai_use_disclaimer: false,
  });
  const [experienceValidationData, setExperienceValidationData] =
    useState<ExperienceValidationData>({
      role: 'pending',
      experience: 'pending',
    });

  const handleValueChange = (data: Partial<UserSettings>) => {
    setSetupFormData((prevData) => ({ ...prevData, ...data }));
  };
  const handleNextStep = () => {
    if (currentStep === totalSteps) return;
    if (currentStep === 4) {
      if (!validateExperienceStep()) {
        return;
      }
    }

    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep === 0) return;
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const validateExperienceStep = () => {
    const { role, year_of_employment } = setupFormData;
    if (role && year_of_employment) {
      setExperienceValidationData({
        role: 'valid',
        experience: 'valid',
      });
      return true;
    } else {
      setExperienceValidationData({
        role: role ? 'valid' : 'invalid',
        experience: year_of_employment ? 'valid' : 'invalid',
      });
      return false;
    }
  };

  const onComplete = () => {
    if (validateExperienceStep()) {
      onSave(userSetupData.id, userSetupData.name, {
        ...setupFormData,
        user_setup_complete: true,
      });
    }
  };

  // Set up key event listeners for navigating the wizard.
  useEffect(() => {
    const container = wizardContainerRef.current;
    if (!container) return;

    const steps = container.children.length - 1;

    const handleArrowNavigation = (event: KeyboardEvent) => {
      if (event.key === 'ArrowRight') {
        setCurrentStep((prevStep) => {
          if (prevStep + 1 <= steps) {
            return prevStep + 1;
          }
          return prevStep;
        });
      } else if (event.key === 'ArrowLeft') {
        setCurrentStep((prevStep) => {
          if (prevStep - 1 >= 0) {
            return prevStep - 1;
          }
          return prevStep;
        });
      }
    };
    document.addEventListener('keydown', handleArrowNavigation);

    return () => {
      document.removeEventListener('keydown', handleArrowNavigation);
    };
  }, []);

  const steps: JSX.Element[] = [
    <LanguageStep
      key='language'
      current={currentStep === 0}
      onValueChange={handleValueChange}
    />,
    <IntroStep
      key='intro'
      current={currentStep === 1}
      name={userSetupData.name.firstName}
    />,
    <RegionStep
      key='region'
      current={currentStep === 2}
      onValueChange={handleValueChange}
      setupSettings={setupFormData}
    />,
    <BusinessSegmentStep
      key='business-segment'
      current={currentStep === 3}
      onValueChange={handleValueChange}
      setupSettings={setupFormData}
    />,
    <ExperienceStep
      key='experience'
      current={currentStep === 4}
      onValueChange={handleValueChange}
      validationData={experienceValidationData}
      setupSettings={setupFormData}
    />,
    <DisclaimerStep
      key='disclaimer'
      current={currentStep === 5}
      onValueChange={handleValueChange}
    />,
  ];

  const totalSteps = steps.length - 1;
  const stepOffset = userIsNotSetup ? currentStep * 100 : 0;

  return (
    <>
      <div className='account-wizard-wrapper'>
        <div
          ref={wizardContainerRef}
          style={{ transform: `translateX(-${stepOffset}%)` }}
          className='account-wizard-container'
        >
          {userIsNotSetup ? steps : <SetupCompleteStep />}
        </div>
      </div>
      {userIsNotSetup ? (
        <div className='account-setup-footer'>
          <StepOverView currentStep={currentStep} totalSteps={totalSteps} />
          <div className='account-setup-actions'>
            <button
              data-hidden={currentStep === 0}
              aria-hidden={currentStep === 0}
              className='account-setup-action-previous'
              onClick={handlePreviousStep}
            >
              Previous
            </button>
            <p className='account-setup-step-indicator'>
              {`${currentStep + 1} of ${totalSteps + 1}`}
            </p>
            {currentStep < totalSteps ? (
              <button
                className='account-setup-action-forward'
                onClick={handleNextStep}
              >
                Next
              </button>
            ) : (
              <button
                className={`account-setup-action-forward ${
                  !setupFormData.accepted_ai_use_disclaimer ? 'disabled' : ''
                }`}
                onClick={onComplete}
                disabled={!setupFormData.accepted_ai_use_disclaimer}
                title={
                  !setupFormData.accepted_ai_use_disclaimer
                    ? 'Please accept the terms and conditions to proceed.'
                    : ''
                }
              >
                Complete
              </button>
            )}
          </div>
        </div>
      ) : (
        <p className='account-setup-complete-disclaimer'>
          <b>Note! </b>
          Technician CoPilot may display inaccurate AI-generated content, please
          always verify responses and consider the{' '}
          <a
            data-metrics-id='SafetyPolicyLink'
            className='chat-disclaimer-link'
            href='https://avenue.assaabloy.net/group-library/health-and-safety'
            target='_blank'
          >
            ASSA ABLOY Safety policy, directive and guidelines.
          </a>
        </p>
      )}
    </>
  );
};

const StepOverView = ({
  currentStep,
  totalSteps,
}: {
  currentStep: number;
  totalSteps: number;
}) => {
  return (
    <div className='account-setup-step-overview'>
      <div className='account-setup-step-progress'>
        {[...Array(totalSteps + 1)].map((_, index) => (
          <div
            key={index}
            data-complete={index < currentStep}
            data-current={index === currentStep}
            className='account-setup-step-dot'
          ></div>
        ))}
      </div>
    </div>
  );
};
