import { Product } from './product';
import { BusinessSegment, Language, Region } from './user';

export type AskResponse = {
  answer: string;
  citations: Citation[];
  error?: string;
};

export type CitationMetadata = {
  start_page: string;
  chunk_id: string;
  container: string;
  commit_SHA: string;
  date: string;
};

export type MessageMetadata = {
  speech?: boolean;
  language?: Language;
};

export type Metadata = {
  [key: string]: string;
};

export type Citation = {
  refkey: string;
  content: string;
  id?: string;
  title: string;
  filepath: string;
  url: string;
  metadata: Metadata | CitationMetadata;
  chunk_id?: string;
  reindex_id?: string;
};

export type AIAnswerData = {
  role: 'assistant' | 'tool';
  content: string;
  completed?: boolean;
  paraphrase?: string;
};

export type ToolMessage = {
  id?: string;
  citations: Citation[];
};

export type ToolAnswer = {
  citations: Citation[];
  intent: string;
};

export type ChatMessageRole = 'user' | 'assistant' | 'system' | 'interaction';

export type MessageType = 'text' | 'speech' | 'image';

export type MessageContent = {
  role: ChatMessageRole;
  content: string;
};

export type ChatMessage = {
  role: 'user' | 'assistant';
  id: string;
  date: string;
  content: string;
  metadata?: MessageMetadata;
  tool_message?: ToolMessage;
};

export type MessageData = {
  type?: MessageType;
  messageContent: ChatMessage;
  loading?: boolean;
  onCancel?: () => void;
};

export type UserInteraction = {
  role: 'interaction';
  id: string;
  date: string;
  question: string;
  choices: Product[];
};

export enum ConversationState {
  UNKNOWN = 'unknown',
  IDENTIFICATION = 'identification',
  ADDITIONAL_INFORMATION = 'additional_information',
  PRODUCT_SELECTION = 'product_selection',
  TROUBLESHOOTING = 'troubleshooting',
}

export enum InstructionType {
  PRODUCT_SELECTION = 'product_selection',
  ADDITIONAL_INFORMATION = 'additional_information',
}

export enum ActionType {
  UNKNOWN = 'unknown',
  TOOL_SELECTION = 'tool_selection',
}

export type Subject = {
  brand?: string;
  product_name?: string;
  product_group?: string;
  product_type?: string;
  region?: string;
};

export type PlanningData = {
  action: ActionType;
  tool: ConversationState;
  subjects?: Subject[];
};

export type PlanningMessage = {
  id?: string;
  date: string;
  role: 'system';
  planning: PlanningData;
  subject?: string;
  state?: ConversationState;
};

export type Instruction = {
  action: InstructionType;
  options?: Subject[];
};

export type Message = ChatMessage | UserInteraction | PlanningMessage;

export type Conversation = {
  id?: string;
  region: Region;
  created_at?: string;
  updated_at?: string;
  title?: string;
  language?: Language;
  messages: Message[];
  subjects?: Subject[];
  user_specified?: Subject[];
  data_filter?: BusinessSegment[];
};

const RAW_STREAM_EVENT_KEYS = ['event', 'data', 'id'] as const;

export type RawStreamEventKey = (typeof RAW_STREAM_EVENT_KEYS)[number];

export const isRawStreamEventKey = (
  value: string,
): value is RawStreamEventKey => {
  return RAW_STREAM_EVENT_KEYS.includes(value as RawStreamEventKey);
};

type RawStreamEventValueMap = {
  event: ConversationStreamEvent;
  data: string;
  id: string;
};

export type RawStreamEvent = {
  [key in RawStreamEventKey]: RawStreamEventValueMap[key];
};

const ALL_CONVERSATION_STREAM_EVENTS = [
  'conversation',
  'citations',
  'planning',
  'instruction',
  'subjects',
  'related_subjects',
] as const;

export type ConversationStreamEvent =
  (typeof ALL_CONVERSATION_STREAM_EVENTS)[number];

export const isConversationStreamEvent = (
  value: string,
): value is ConversationStreamEvent => {
  return ALL_CONVERSATION_STREAM_EVENTS.includes(
    value as ConversationStreamEvent,
  );
};

export type ConversationStreamEventData = {
  id: string;
} & (
  | { event: 'conversation' | 'citations'; data: AIAnswerData }
  | { event: 'planning'; data: PlanningMessage }
  | {
      event: 'instruction';
      data: Instruction;
    }
  | {
      event: 'subjects';
      data: Subject[];
    }
  | {
      event: 'related_subjects';
      data: Product[];
    }
);

export enum ChatCompletionType {
  ChatCompletion = 'chat.completion',
  ChatCompletionChunk = 'chat.completion.chunk',
}

export type Feedback = {
  conversation_id: string;
  message_id: string;
  feedback_rating: 'positive' | 'negative';
  feedback_choices: string[];
  feedback: string;
};

export type SpeechToken = {
  authToken: string | null;
  region?: string;
  error?: string;
};
