import { forwardRef, useEffect, useState } from 'react';
import Select, { SelectInstance, StylesConfig } from 'react-select';
import './CustomDropdown.scss';
import { useAppContext } from '../../state/useAppProvider';

export type DropdownValue = { value: string; label: string };

type CustomDropdownProps = {
  name: string;
  validated?: InputValidationState;
  options: DropdownValue[];
  onChange: (value?: DropdownValue) => void;
  onClick?: () => void;
  value?: string;
  useFloatingLabel?: boolean;
  metricsId?: string;
  absolutePosition?: boolean;
  compactHeight?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  toggleDocumentPanel?: boolean;
};

export type InputValidationState = 'pending' | 'valid' | 'invalid';

export const CustomDropdown = forwardRef<
  SelectInstance<DropdownValue>,
  CustomDropdownProps
>(
  (
    {
      name,
      validated,
      options,
      onChange,
      onClick,
      value,
      useFloatingLabel = true,
      metricsId,
      absolutePosition = true,
      compactHeight = false,
      menuPlacement = 'auto',
      toggleDocumentPanel = false,
    }: CustomDropdownProps,
    ref,
  ) => {
    const { dispatch } = useAppContext();
    const [floatingLabel, setFloatingLabel] = useState(false);
    const [isFocused, setIsFocused] = useState(false);

    const dropDownStyles: StylesConfig<DropdownValue> = {
      control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused
          ? 'var(--accent)'
          : validated === 'invalid'
            ? 'var(--text-color-error)'
            : baseStyles.borderColor,
        backgroundColor:
          validated === 'invalid'
            ? 'var(--text-color-error-soft)'
            : baseStyles.backgroundColor,
        boxShadow: state.isFocused
          ? '0px 0 0px 4px var(--accent-outline)'
          : baseStyles.boxShadow,
        '&:hover': {
          borderColor: 'var(--accent)',
        },
      }),
      menu: (baseStyles) => ({
        ...baseStyles,
        position: absolutePosition ? 'absolute' : 'relative',
      }),
    };

    const handleChange = (value?: DropdownValue) => {
      onChange(value);
    };

    useEffect(() => {
      if (!value && !isFocused) {
        setFloatingLabel(false);
      } else {
        setFloatingLabel(true);
      }
    }, [value, isFocused]);

    if (options.length === 0) return null;

    return (
      <div className='product-choice-dropdown-container'>
        <Select
          ref={ref}
          maxMenuHeight={compactHeight ? 150 : undefined}
          data-metrics-id={metricsId}
          className='product-choice-dropdown'
          classNamePrefix={'product-choice-dropdown'}
          styles={dropDownStyles}
          placeholder=''
          options={options}
          isClearable
          name={name}
          inputId={name}
          isMulti={false}
          onChange={(value) => handleChange(value ?? undefined)}
          onFocus={() => {
            onClick && onClick();
            toggleDocumentPanel &&
              dispatch({ type: 'SET_HIDE_DOCUMENT_PANEL', payload: true });
            setIsFocused(true);
          }}
          onBlur={() => {
            toggleDocumentPanel &&
              dispatch({ type: 'SET_HIDE_DOCUMENT_PANEL', payload: false });
            setIsFocused(false);
          }}
          value={value ? { value, label: value } : null}
          menuPlacement={menuPlacement}
        />
        {useFloatingLabel && (
          <label
            data-selected={floatingLabel}
            className='product-choice-label'
            htmlFor={name}
          >
            {name}
          </label>
        )}
      </div>
    );
  },
);
